import Image from 'next/image'
import styled from 'styled-components'

export const QrCodeStyled = styled(Image)<{ $size: number }>`
  width: ${p => p.$size}px;
  height: ${p => p.$size}px;

  ${p => p.theme.mediaQueries.desktopXS} {
    order: 1;
  }
`
