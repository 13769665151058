import Image from 'next/image'
import styled from 'styled-components'

import {
  LinkDownload,
  MobileAppDownloadLinksWrapper,
  MobileAppDownloadWrapper,
  Title,
} from '@components/MobileAppDownload/styles'
import { QrCodeStyled } from '@components/QrCode/styles'

export const ApplicationLinksContainer = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 48px 72px;
  width: 100%;
  background-color: ${p => p.theme.colors.black};
  border-radius: ${p => p.theme.borderRadius.sm};
  overflow: hidden;
  margin-bottom: 8px;

  ${p => p.theme.mediaQueries.mobileTablet} {
    padding: 32px;

    ${QrCodeStyled} {
      order: 0;
    }
  }

  ${p => p.theme.mediaQueries.mobile} {
    padding: 24px;
    border-radius: 0;
    margin-inline: -16px;
    width: calc(100% + 32px);

    ${QrCodeStyled} {
      display: none;
    }
  }

  ${Title} {
    color: ${p => p.theme.colors.white};
  }

  ${MobileAppDownloadWrapper} {
    z-index: 1;

    ${LinkDownload}.ant-btn {
      &,
      &:active,
      &:not(:disabled):not(.ant-btn-disabled):active,
      &:focus,
      &:not(:disabled):not(.ant-btn-disabled):focus,
      &:hover,
      &:not(:disabled):not(.ant-btn-disabled):hover {
        background: ${props => props.theme.colors.black};
        width: 180px;
      }
    }

    ${p => p.theme.mediaQueries.mobileTablet} {
      flex-direction: column;

      ${MobileAppDownloadLinksWrapper} {
        width: 100%;

        ${LinkDownload} {
          flex: 1;
        }
      }
    }

    ${p => p.theme.mediaQueries.mobile} {
      ${MobileAppDownloadLinksWrapper} {
        row-gap: 16px;
      }
    }
  }
`

export const BackgroundImage = styled(Image)`
  position: absolute;
  inset: 0;
  z-index: 0;
  display: block;
  filter: blur(12px);
  width: 100%;
  height: 100%;
  object-fit: cover;
`
